import * as Sentry from '@sentry/remix';
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import posthog from 'posthog-js';

Sentry.init({
  dsn: 'https://fe6fd0f17c07d9f45c02c8b7463df53f@o4507217598611456.ingest.us.sentry.io/4507217602543616',
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  environment: process.env.NODE_ENV,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
});

function PosthogInit() {
  useEffect(() => {
    posthog.init('phc_AOyOBE3maVGa1GgIg6y9D2pJl1EHY1wkSpioM9cZZVv', {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only',
    });
  }, []);

  return null;
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
      <PosthogInit />
    </StrictMode>
  );
});
